import React, { Component } from 'react';
import { MyContext } from '../../../contexts/MyContext';
import toast from 'react-hot-toast';

class VentasExtra extends Component {

    static contextType = MyContext;

    constructor(props) {
        super(props);
        this.state = {
            ventasExtra: []
        }
    }

    componentDidMount = async () => {
        window.addEventListener('resize', this.getHeightWindow);
        this.getHeightWindow();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select', sucursal: this.props.idSucursal, fecha: this.props.fecha })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/ventasextra.php', requestOptions);
        const data = await response.json();
        this.setState({
            ventasExtra: data
        })
    }

    componentDidUpdate = (prevProps) => {
        if ((prevProps.idSucursal !== this.props.idSucursal) || (prevProps.fecha !== this.props.fecha)) {
            this.componentDidMount();
        }
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("containerVentas")[0]) {
            document.getElementsByClassName("containerVentas")[0].style.height = (height - 170) + 'px';
        }
    }

    eliminarVentaExtra = async (id, fecha, concepto, total) => { 
        let fechaHoy = new Date().toLocaleDateString();
        let fechaVentaExtra = new Date(fecha).toLocaleDateString();
        if (fechaHoy === fechaVentaExtra) {
            if (window.confirm('¿Borrar venta extra?')) {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        operacion: 'delete', 
                        usuario: this.context.rootState.theUser.idUsuario,
                        sucursal: this.props.idSucursal, 
                        idVentaExtra: id,
                        concepto: concepto,
                        total: total
                    })
                };
                const response = await fetch('https://admin.panaderialazcano.com/php/DB/ventasextra.php', requestOptions);
                const data = await response.json();
                if (data.error === 0) {
                    toast.success(data.mensaje);
                    this.componentDidMount();
                }else{
                    if (data.codigoError === '22003') {
                        toast.error('Revise sus cobros.');
                    }else{
                        toast.error('Hubo un error al realizar la petición.');
                        console.log(data.mensaje);
                    }
                }
            }
        }else{
            toast.dismiss();
            toast.error('Solo puede borrar ventas extra del día en curso.');
        }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value 
        });
    }

    render() { 
        return ( 
            <>
                <div className="container-fluid ventas">
                    <div className="row d-flex justify-content-center">
                        <div className="col-8 px-4 pt-4">
                            <div className="row">
                                <div className='containerVentas' style={{ backgroundColor: '#e1e4c8' }}>
                                    <div className="row py-2" style={{ borderBottom: '5px solid #afc37f' }}>
                                        <div className="col-3 text-center">
                                            Fecha
                                        </div>
                                        <div className="col-3 text-center">
                                            Concepto
                                        </div>
                                        <div className="col-3 text-center">
                                            Total
                                        </div>
                                        <div className="col-3 text-center">
                                            -
                                        </div>
                                    </div>
                                    {
                                        this.state.ventasExtra[0] ?
                                            this.state.ventasExtra.map((e, index) =>
                                                <div className="row elementoVentas py-3" key={index}>
                                                    <div className="col-3 d-flex justify-content-center align-items-center">
                                                        <p className='m-0'>{new Date(e.fecha).toLocaleDateString()}</p>
                                                    </div>
                                                    <div className="col-3 d-flex align-items-center">
                                                        {e.concepto}
                                                    </div>
                                                    <div className="col-3 d-flex justify-content-end align-items-center">
                                                        ${this.numberWithCommas(e.total)}
                                                    </div>
                                                    <div className="col-3 d-flex justify-content-center align-items-center">
                                                        <button className='btn btn-success ms-1 btnFinalizarVenta' onClick={() => this.eliminarVentaExtra(e.idVentaExtra, e.fecha, e.concepto, this.numberWithCommas(e.total))}><i className="bi bi-trash2-fill"></i></button>
                                                    </div>
                                                </div>
                                            )
                                            :
                                            <div className="row mt-3">
                                                <div className="col-12 text-center">
                                                    No hay ventas extra registradas en esta fecha.
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
         );
    }
}
 
export default VentasExtra;