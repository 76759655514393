import React from 'react';
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarContent, SidebarFooter, SubMenu } from 'react-pro-sidebar';
import '../css/sidebar.scss';
import '../css/sidebar.css';
import { MyContext } from '../contexts/MyContext';

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            usuario: ''
        }
    }

    static contextType = MyContext;

    componentDidMount = () => {
        
    }

    CerrarSesion = () => {

    }

    render() { 
        return ( 
            <ProSidebar breakPoint='md' toggled={this.props.toggledSidebar} onToggle={this.props.toggleSidebar}>
                <SidebarHeader style={{ height: '50px', border: 'none', backgroundColor: '#876b4b' }}>
                    <div className="row h-100 m-0">
                        <div className="col-12 d-flex align-items-center">
                            <p className='m-0 p-0'>{this.context.rootState.theUser.nombre + ' ' + this.context.rootState.theUser.apellidos}</p>
                        </div>
                    </div>  
                </SidebarHeader>
                <SidebarContent className='sidebarContentBg'>
                    <Menu>
                        <MenuItem icon={<i className="bi bi-house-fill" style={{ fontSize: '20px' }}></i>}>
                            <a href="/">Inicio</a>
                        </MenuItem>
                        <MenuItem icon={<i className="bi bi-shop" style={{ fontSize: '20px' }}></i>}>
                            <a href="/sucursales">Sucursales</a>
                        </MenuItem>
                        <MenuItem icon={<i className="bi bi-shop-window" style={{ fontSize: '20px' }}></i>}>
                            <a href="/expendios">Expendios</a>
                        </MenuItem>
                        <MenuItem icon={<i className="bi bi-pencil-square" style={{ fontSize: '20px' }}></i>}>
                            <a href="/registroexpendio">Registro de Expendio</a>
                        </MenuItem>
                        <MenuItem icon={<i className="bi bi-cash" style={{ fontSize: '20px' }}></i>}>
                            <a href="/cobrosexpendios">Cobros a Expendios</a>
                        </MenuItem>
                        <SubMenu title={"Productos"} icon={<i className="bi bi-bag-fill" style={{ fontSize: '20px' }}></i>}>
                            <MenuItem icon={<i className="bi bi-dash"></i>}>
                                <a href="/panes">Panes</a>
                            </MenuItem>
                            <MenuItem icon={<i className="bi bi-dash"></i>}>
                                <a href="/abarrotes">Abarrotes</a>
                            </MenuItem>
                            <MenuItem icon={<i className="bi bi-dash"></i>}>
                                <a href="/otros">Otros</a>
                            </MenuItem>
                        </SubMenu>
                        <MenuItem icon={<i className="bi bi-person-fill" style={{ fontSize: '20px' }}></i>}>
                            <a href="/usuarios">Usuarios</a>
                        </MenuItem>
                    </Menu>
                </SidebarContent>
                <SidebarFooter className='p-2 text-center' style={{ border: 'none', backgroundColor: '#947450' }}>
                    <button onClick={() => window.location.reload()} type='button' className='btn px-3 mx-2' style={{ border: 'none', color: 'white', backgroundColor: 'rgba(0,0,0,0.1)' }}>
                        <i className="bi bi-arrow-clockwise"></i>
                    </button>
                    <button onClick={() => this.context.logoutUser()} type='button' className='btn px-3 mx-2' style={{ border: 'none', color: 'white', backgroundColor: 'rgba(0,0,0,0.1)' }}>
                        <i className="bi bi-box-arrow-left"></i>
                    </button>
                </SidebarFooter>
            </ProSidebar>
         );
    }
}
 
export default Sidebar;