import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { NumericFormat } from 'react-number-format';

class ChangePrecios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    editar = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'updatePrecios', idProducto: this.props.idProducto, precios: this.props.preciosPan })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/productos.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            toast.success('Pan editado');
            this.props.hideModal();
            this.props.componentDidMount();
        }else{
            toast.error('Error al editar pan.');
            console.log(data.mensaje);
        }
    }

    render() { 
        return ( 
            <Modal show={this.props.show} onHide={this.props.hideModal} centered>
                <form onSubmit={this.editar}>
                <Modal.Header>
                    <h1 className='m-0' style={{ fontSize: '24px' }}>Cambiar Precios {this.props.nombre}</h1>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.props.sucursales.map(e =>
                                <div key={e.idSucursal} className='input-group mt-2'>
                                    <span className='input-group-text'>{e.tipo} {e.nombre}</span>
                                    <NumericFormat thousandSeparator=',' className='form-control text-end' value={this.props.preciosPan[this.props.preciosPan.findIndex(item => item.idSucursal === e.idSucursal)].precio} defaultValue={''} placeholder='0.0' onValueChange={(values) => this.props.onChangePrecioSucursal(this.props.preciosPan.findIndex(item => item.idSucursal === e.idSucursal), values.value)} allowNegative={false} decimalScale={2} prefix={'$'}/>
                                </div>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ backgroundColor: '#333333', color: 'white' }} type='button' onClick={this.props.hideModal}>Cancelar</button>
                    <button className='btn btn-success text-white' type='submit'>Editar</button>
                </Modal.Footer>
                </form>
            </Modal>
         );
    }
}
 
export default ChangePrecios;