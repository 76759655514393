import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class ChangeImg extends Component {
    constructor(props) {
        super(props);
        this.imgPrincipal = React.createRef()
        this.state = {
            urlmgP: ''
        }
    }

    select = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'buscarImagen', idProducto: this.props.idProducto })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/productos.php', requestOptions);
        const data = await response.json();
        if (data.existe === true) {
            this.setState({
                urlmgP: 'https://pos.panaderialazcano.com/img/productos/' + this.props.idProducto + '.png'
            })
        }
        console.log(data);
    }

    hideModal = () => {
        this.props.hideModal();
        this.setState({
            urlmgP: ''
        })
    }

    render() { 
        return ( 
            <Modal show={this.props.show} onHide={this.hideModal} centered onShow={this.select}>
                <form action="">
                <Modal.Header>
                    <h1 className='m-0' style={{ fontSize: '24px' }}>Cambiar Imagen {this.props.nombre}</h1>
                </Modal.Header>
                <Modal.Body>
                    <input type="file" className='form-control' ref={this.imgPrincipal} accept="image/png" required onChange={this.onChangeImgP} hidden={this.state.urlmgP !== ''}/>
                    <div className="text-center">
                        <img src={this.state.urlmgP} alt="" style={{ maxHeight: '400px', maxWidth: '300px' }} className='my-3'/>
                    </div>
                    <div className='text-center'>
                        <button type='button' className='btn w-25 btn-danger' onClick={this.cambiarImgP} hidden={this.state.urlmgP === ''}>{ this.state.urlmgP ? 'Quitar' : 'Agregar' }</button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-success' type='button' style={{ width: '150px' }} onClick={this.editar}>Editar</button>
                </Modal.Footer>
                </form>
            </Modal>
         );
    }
}
 
export default ChangeImg;