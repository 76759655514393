import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class ChangeImg extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() { 
        return ( 
            <Modal show={this.props.show} onHide={this.props.hideModal} centered>
                <Modal.Header>
                    <h1 className='m-0' style={{ fontSize: '24px' }}>Cambiar Imagen {this.props.nombre}</h1>
                </Modal.Header>
                <Modal.Body>

                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
         );
    }
}
 
export default ChangeImg;