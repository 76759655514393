import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';

class AgregarUsuario extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidePassword: true
        }
    }

    agregar = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'insert', nombre: this.props.nombre, apellidos: this.props.apellidos, tipo: this.props.tipo, us_idSucursal: this.props.us_idSucursal, contrasena: this.props.contrasena, acceso: this.props.acceso, horaEntrada: this.props.horaEntrada, horaSalida: this.props.horaSalida })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/usuarios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            toast.success('Uuario agregado.');
            this.props.hideModal();
            this.props.componentDidMount();
        }else{
            toast.error('Error al agregar usuario.');
            console.log(data.mensaje);
        }
    }

    togglePassword = () => {
        this.setState(prevState => ({
            hidePassword: !prevState.hidePassword
        }))
    }

    render() { 
        return ( 
            <Modal show={this.props.show} onHide={this.props.hideModal} centered>
                <form onSubmit={this.agregar}>
                <Modal.Header className='d-flex justify-content-center'>
                    <h1 className='m-0' style={{ fontSize: '24px' }}>Agregar Usuario</h1>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-6">
                            <label htmlFor="nombre">Nombre</label>
                                <input type="text" name="nombre" id="nombre" className='form-control' value={this.props.nombre} onChange={this.props.onChangeValue} required/>
                        </div>
                        <div className="col-6">
                            <label htmlFor="apellidos">Apellidos</label>
                                <input type="text" name="apellidos" id="apellidos" className='form-control' value={this.props.apellidos} onChange={this.props.onChangeValue} required/>
                        </div>
                        <div className="col-12">
                            <label htmlFor="contrasena">Contraseña</label>
                            <div className="input-group">
                                <input type={this.state.hidePassword ? "password" : "text"} name="contrasena" id="contrasena" className='form-control' pattern="^[A-Za-z\d]{8,20}$" title="La contraseña debe tener entre 8 y 20 caracteres, sin incluir caracteres especiales." value={this.props.contrasena} onChange={this.props.onChangeValue} required/>
                                <button className="input-group-text" type='button' onClick={() => this.togglePassword()}><i className={this.state.hidePassword ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"}></i></button>
                            </div>
                        </div>
                        <div className="col-6">
                            <label htmlFor="tipo" className='mt-2'>Tipo</label>
                                <select name="tipo" id="tipo" className='form-control' value={this.props.tipo} onChange={this.props.onChangeValue} required>
                                    <option value="">Seleccionar</option>
                                    <option value="administrador">Administrador</option>
                                    <option value="cajero">Cajero</option>
                                </select>
                        </div>
                        <div className="col-6">
                            <label htmlFor="us_idSucursal" className='mt-2'>Sucursal</label>
                                <select name="us_idSucursal" id="us_idSucursal" className='form-control' value={this.props.us_idSucursal} onChange={this.props.onChangeValue} required={this.props.tipo !== 'administrador'} disabled={this.props.tipo === 'administrador'}>
                                    <option value="">Seleccionar</option>
                                    {
                                        this.props.sucursales.map(e =>
                                            <option value={e.idSucursal} key={e.idSucursal}>{e.nombre}</option>
                                        )
                                    }
                                </select>
                        </div>
                        <div className="col-6" hidden={this.props.tipo !== 'cajero'}>
                            <label htmlFor="acceso" className='mt-2'>Acceso</label>
                            <select name="acceso" id="acceso" className='form-control' value={this.props.acceso} onChange={this.props.onChangeValue} required={this.props.tipo === 'cajero'}>
                                <option value="">Seleccionar</option>
                                <option value="horario">Horario</option>
                                <option value="permitir">Permitir</option>
                                <option value="denegar">Denegar</option>
                            </select>
                        </div>
                        <div className="col-6" hidden={this.props.tipo !== 'cajero'}></div>
                        <div className="col-6" hidden={this.props.tipo !== 'cajero'}>
                            <label htmlFor="horaEntrada" className='mt-2'>Hora de Entrada</label>
                                <input type="time" name="horaEntrada" id="horaEntrada" className='form-control' value={this.props.horaEntrada} onChange={this.props.onChangeValue} required={this.props.tipo === 'cajero'}/>
                        </div>
                        <div className="col-6" hidden={this.props.tipo !== 'cajero'}>
                            <label htmlFor="horaSalida" className='mt-2'>Hora de Salida</label>
                                <input type="time" name="horaSalida" id="horaSalida" className='form-control' value={this.props.horaSalida} onChange={this.props.onChangeValue} required={this.props.tipo === 'cajero'}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ backgroundColor: '#333333', color: 'white' }} type='button' onClick={() => this.props.hideModal()}>Cancelar</button>
                    <button className='btn btn-success text-white' type='submit'>Agregar</button>
                </Modal.Footer>
                </form>
            </Modal>
         );
    }
}
 
export default AgregarUsuario;