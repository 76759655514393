import React, { Component } from 'react';
import {MyContext} from '../../contexts/MyContext';
import '../../css/sucursales.css';
import { toast } from 'react-hot-toast';
import AgregarUsuario from './AgregarUsuario';
import EditarUsuario from './EditarUsuario';
import CambiarContrasena from './CambiarContrasena';

class Usuarios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usuarios: [],
            sucursales: [],
            idUsuario: '',
            nombre: '',
            apellidos: '',
            tipo: '',
            us_idSucursal: '',
            contrasena: '',
            acceso: '',
            horaEntrada: '',
            horaSalida: '',
            showModalAgregar: false,
            showModalEditar: false,
            showModalContrasena: false
        }
    }

    static contextType = MyContext;

    componentDidMount = async() => {
        document.title = 'Usuarios';
        this.context.cambiarRuta('Usuarios');
        window.addEventListener('beforeunload', this.alertUser);
        this.selectSucursales();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectAll' })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/usuarios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                usuarios: data.usuarios
            })
        }else{
            toast.error('Error al obtener usuarios.');
            console.log(data.mensaje);
        }
    }
    
    alertUser = (e) => {
        if (this.state.showModalAgregar || this.state.showModalEditar || this.state.showModalContrasena) {
            e.preventDefault();
            e.returnValue = '';  
        }
    }

    selectSucursales = async() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select' })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/sucursales.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                sucursales: data.sucursales
            })
        }else{
            toast.error('Error al obtener sucursales.');
            console.log(data.mensaje);
        }
    }

    onChangeValue = (e) => {
        if (e.target.name === 'tipo') {
            if (e.target.value === 'administrador') {
                this.setState({
                    [e.target.name]: e.target.value,
                    us_idSucursal: '',
                    acceso: '',
                    horaEntrada: '',
                    horaSalida: ''
                })
            }else if(e.target.value === 'cajero'){
                this.setState({
                    [e.target.name]: e.target.value,
                })
            }else{
                this.setState({
                    [e.target.name]: e.target.value,
                    acceso: '',
                    horaEntrada: '',
                    horaSalida: ''
                })
            }
        }else{
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    showModalAgregar = () => {
        this.setState({
            showModalAgregar: true
        })
    }

    hideModalAgregar = () => {
        this.setState({
            showModalAgregar: false,
            nombre: '',
            apellidos: '',
            tipo: '',
            us_idSucursal: '',
            contrasena: '',
            acceso: '',
            horaEntrada: '',
            horaSalida: ''
        })
    }

    showModalEditar = (idUsuario, nombre, apellidos, tipo, us_idSucursal, acceso, horaEntrada, horaSalida) => {
        this.setState({
            showModalEditar: true,
            idUsuario: idUsuario,
            nombre: nombre,
            apellidos: apellidos,
            tipo: tipo,
            us_idSucursal: us_idSucursal ? us_idSucursal : '',
            acceso: acceso ? acceso : '',
            horaEntrada: horaEntrada ? horaEntrada : '',
            horaSalida: horaSalida ? horaSalida : ''
        })
    }

    hideModalEditar = () => {
        this.setState({
            showModalEditar: false,
            idUsuario: '',
            nombre: '',
            apellidos: '',
            tipo: '',
            us_idSucursal: '',
            acceso: '',
            horaEntrada: '',
            horaSalida: ''
        })
    }

    showModalContrasena = (idUsuario, nombre, apellidos) => {
        this.setState({
            showModalContrasena: true,
            idUsuario: idUsuario,
            nombre: nombre,
            apellidos: apellidos
        })
    }

    hideModalContrasena = () => {
        this.setState({
            showModalContrasena: false,
            idUsuario: '',
            nombre: '',
            apellidos: '',
            contrasena: ''
        })
    }

    eliminar = async(idUsuario) => {
        if (this.context.rootState.theUser.idUsuario === idUsuario) {
            toast.error('No puede borrar el usuario activo.');
        }else{
            if (window.confirm('¿Eliminar usuario?')) {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ operacion: 'delete', idUsuario: idUsuario })
                };
                const response = await fetch('https://admin.panaderialazcano.com/php/DB/usuarios.php', requestOptions);
                const data = await response.json();
                if (data.error === 0) {
                    toast.success('Uuario eliminado.');
                    this.componentDidMount();
                }else{
                    toast.error('Error al eliminar usuario.');
                    console.log(data.mensaje);
                }
            }
        }
    }

    render() { 
        return ( 
            <>
                <div className='contenedorPrincipal'>
                    <div className="row m-0 p-3">
                        <div className="col-12 d-flex justify-content-center align-items-center">
                            <button className='btn btn-success btnAgregar' onClick={() => this.showModalAgregar()}>
                                <i className="bi bi-plus" style={{ fontSize: '40px', color: 'white' }}></i>
                            </button>
                        </div>
                        <div className="col-12 mt-4">
                            <table className='table table-bordered'>
                                <thead>
                                    <tr>
                                        <th>Sucursal</th>
                                        <th>Nombre</th>
                                        <th>Rol</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.usuarios.map(e => 
                                            <tr key={e.idUsuario}>
                                                <td>{e.nombreSucursal}</td>
                                                <td>{e.nombre} {e.apellidos}</td>
                                                <td style={{ textTransform: 'capitalize' }}>
                                                    <p className='m-0'>{e.tipo}</p>
                                                    {e.tipo === 'cajero' && <p className='m-0' style={{ fontSize: '12px' }}>Acceso: {e.acceso}</p>}
                                                    {e.tipo === 'cajero' && <p className='m-0' style={{ fontSize: '12px' }}>Horario: {new Date(`2000-01-01T${e.horaEntrada}`).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })} - {new Date(`2000-01-01T${e.horaSalida}`).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })}</p>}                                              
                                                </td>
                                                <td className=''>
                                                    <div className='d-flex justify-content-evenly mt-2'>
                                                        <button className='btn btn-primary' onClick={() => this.showModalContrasena(e.idUsuario, e.nombre, e.apellidos)}><i className="bi bi-key-fill text-white"></i></button>
                                                        <button className='btn btn-dark' onClick={() => this.showModalEditar(e.idUsuario, e.nombre, e.apellidos, e.tipo, e.us_idSucursal, e.acceso, e.horaEntrada, e.horaSalida)}><i className="bi bi-pencil-square"></i></button>
                                                        <button className='btn btn-danger' onClick={() => this.eliminar(e.idUsuario)}><i className="bi bi-trash2-fill text-white"></i></button>
                                                    </div>
                                                </td>
                                            </tr>    
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <AgregarUsuario show={this.state.showModalAgregar} hideModal={this.hideModalAgregar} sucursales={this.state.sucursales} nombre={this.state.nombre} apellidos={this.state.apellidos} tipo={this.state.tipo} us_idSucursal={this.state.us_idSucursal} contrasena={this.state.contrasena} acceso={this.state.acceso} horaEntrada={this.state.horaEntrada} horaSalida={this.state.horaSalida} onChangeValue={this.onChangeValue} componentDidMount={this.componentDidMount}/>
                <EditarUsuario show={this.state.showModalEditar} hideModal={this.hideModalEditar} sucursales={this.state.sucursales} idUsuario={this.state.idUsuario} nombre={this.state.nombre} apellidos={this.state.apellidos} tipo={this.state.tipo} acceso={this.state.acceso} horaEntrada={this.state.horaEntrada} horaSalida={this.state.horaSalida} us_idSucursal={this.state.us_idSucursal} onChangeValue={this.onChangeValue} componentDidMount={this.componentDidMount}/>
                <CambiarContrasena show={this.state.showModalContrasena} hideModal={this.hideModalContrasena} idUsuario={this.state.idUsuario} nombre={this.state.nombre} apellidos={this.state.apellidos} contrasena={this.state.contrasena} onChangeValue={this.onChangeValue} componentDidMount={this.componentDidMount}/>
            </>
         );
    }
}
 
export default Usuarios;