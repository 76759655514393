import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import toast from 'react-hot-toast';

class AgregarPan extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    agregar = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'insert', nombre: this.props.nombre, tipo: 'otro', precios: this.props.preciosPan, inventario: this.props.inventarioPan })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/productos.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            toast.success('Pan agregado.');
            this.props.hideModal();
            this.props.componentDidMount();
        }else{
            toast.error('Error al agregar pan.');
            console.log(data.mensaje);
        }
    }

    render() { 
        return ( 
            <Modal show={this.props.show} onHide={this.props.hideModal} centered>
                <form onSubmit={this.agregar}>
                <Modal.Header className='d-flex justify-content-center'>
                    <h1 className='m-0' style={{ fontSize: '24px' }}>Agregar Otro Producto</h1>
                </Modal.Header>
                <Modal.Body>
                    <label htmlFor="nombre">Nombre</label>
                        <input type="text" name="nombre" id="nombre" className='form-control' value={this.props.nombre} onChange={this.props.onChangeValue} required/>
                    {
                        this.props.sucursales.map(e =>
                            <div className='row mt-2' key={e.idSucursal}>
                                <div className="col-12">
                                    <label>{e.tipo} {e.nombre}</label>
                                </div>
                                <div className="col-6">
                                    <div key={e.idSucursal} className='input-group'>
                                        <span className='input-group-text'>Precio</span>
                                        <NumericFormat thousandSeparator=',' className='form-control text-end' value={this.props.preciosPan.findIndex(item => item.idSucursal === e.idSucursal).precio} defaultValue={''} placeholder='0.0' onValueChange={(values) => this.props.onChangePrecioSucursal(this.props.preciosPan.findIndex(item => item.idSucursal === e.idSucursal), values.value)} allowNegative={false} decimalScale={2} prefix={'$'} required/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div key={e.idSucursal} className='input-group'>
                                        <span className='input-group-text'>Piezas</span>
                                        <input type="number" name="piezas" id="piezas" className='form-control text-end' min={0} step={1} required value={this.props.inventarioPan[this.props.inventarioPan.findIndex(item => item.idSucursal === e.idSucursal)].piezas} onChange={(_) => this.props.onChangeInventarioSucursal(_,this.props.inventarioPan.findIndex(item => item.idSucursal === e.idSucursal))}/>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ backgroundColor: '#333333', color: 'white' }} type='button' onClick={() => this.props.hideModal}>Cancelar</button>
                    <button className='btn btn-success text-white' type='submit'>Agregar</button>
                </Modal.Footer>
                </form>
            </Modal>
         );
    }
}
 
export default AgregarPan;