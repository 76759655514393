import React, { Component } from 'react';
import {MyContext} from '../contexts/MyContext';
import { toast } from 'react-hot-toast';
import CorteDiario from './Reportes/CorteDiario';

class Inicio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sucursales: [],
            idSucursal: '',
            reporte: 'corteDiario'
        }
    }

    static contextType = MyContext;

    componentDidMount = async () => {        
        document.title = 'Inicio';
        this.context.cambiarRuta('Inicio');
        window.addEventListener('resize', this.getHeightWindow);
        window.addEventListener('beforeunload', this.alertUser);
        this.getHeightWindow();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select' })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/sucursales.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                sucursales: data.sucursales
            })
        }else{
            toast.error('Error al obtener sucursales');
            console.log(data.mensaje);
        }
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("contenedorPrincipal")[0]) {
                document.getElementsByClassName("contenedorPrincipal")[0].style.height = (height - 50) + 'px';
        }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() { 
        return ( 
            <>
                <div className='contenedorPrincipal p-3'>
                    <div className="row">
                        <div className="col-2">
                            <label className='ms-1' htmlFor="idSucursal">Sucursal</label>
                            <select name="idSucursal" id="idSucursal" className='form-control' onChange={this.onChangeValue}>
                                <option value="">Seleccionar</option>
                                {
                                    this.state.sucursales.map((e) =>
                                        <option key={e.idSucursal} value={e.idSucursal}>{e.nombre}</option>
                                    )
                                }
                            </select>                            
                        </div>
                        <div className="col-2">
                            <label className='ms-1' htmlFor="Reporte">Reporte</label>
                            <select name="reporte" id="reporte" className='form-control'>
                                <option value="corteDiario">Corte Diario</option>
                            </select>
                        </div>
                        {
                            (this.state.idSucursal !== '' && this.state.reporte === 'corteDiario') && <CorteDiario idSucursal={this.state.idSucursal}/>
                        }
                    </div>
                </div>
            </>
         );
    }
}
 
export default Inicio;