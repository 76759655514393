import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import toast from 'react-hot-toast';

class CambiarPrecio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idSucursal: '',
            accion: 'aumentar',
            monto: ''
        }
    }
    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    hideModal = () => {
        this.setState({
            idSucursal: '',
            accion: 'aumentar',
            monto: ''
        })
        this.props.hideModal();
    }
    
    editar = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'modificarPrecios', tipo: 'pan', idSucursal: this.state.idSucursal, accion: this.state.accion, monto: this.state.monto })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/productos.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            toast.success('Precios editados.');
            this.hideModal();
            this.props.componentDidMount();
        }else{
            toast.error('Error al editar precios.');
            console.log(data.mensaje);
        }
    }


    render() { 
        return ( 
            <Modal show={this.props.show} onHide={this.hideModal} centered>
                <form onSubmit={this.editar}>
                <Modal.Header className='d-flex justify-content-center'>
                    <h1 className='m-0' style={{ fontSize: '24px' }}>Modificar Precios</h1>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <label htmlFor='idSucursal'>Sucursal</label>
                                <select name="idSucursal" id="idSucursal" className='form-control' value={this.state.idSucursal} onChange={this.onChangeValue} required>
                                    <option value="">Seleccionar</option>
                                    <option value="todas">Todas</option>
                                    {
                                        this.props.sucursales.map(e =>
                                            <option value={e.idSucursal} key={e.idSucursal}>{e.tipo} {e.nombre}</option>
                                        )
                                    }
                                </select>
                        </div>
                        <div className="col-6">
                            <label htmlFor="accion" className='mt-2'>Acción</label>
                                <select name="accion" id="accion" className='form-control' value={this.state.accion} onChange={this.onChangeValue} required>
                                    <option value="aumentar">Aumentar</option>
                                    <option value="disminuir">Disminuir</option>
                                </select>
                        </div>
                        <div className="col-6">
                            <label htmlFor="" className='mt-2'>Monto</label>
                                <div className='input-group'>
                                    <span className='input-group-text'>$</span>
                                    <NumericFormat thousandSeparator=',' className='form-control text-end' value={this.state.monto} defaultValue={''} placeholder='0.0' onValueChange={(values) => this.setState({ monto: values.value }) } allowNegative={false} decimalScale={2} required/>
                                </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ backgroundColor: '#333333', color: 'white' }} type='button' onClick={() => this.props.hideModal}>Cancelar</button>
                    <button className='btn btn-success text-white' type='submit'>Agregar</button>
                </Modal.Footer>
                </form>
            </Modal>
         );
    }
}
 
export default CambiarPrecio;