import React, { Component } from 'react';
import {MyContext} from '../../../contexts/MyContext';
import toast ,{ Toaster } from 'react-hot-toast';
import AgregarPan from './AgregarPan';
import ChangeImg from './ChangeImg';
import ChangeNombre from './ChangeNombre';
import ChangePrecios from './ChangePrecios';
import ChangeInventario from './ChangeInventario';
import CambiarPrecio from './CambiarPrecio';

class Panes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sucursales: [],
            productos: [],
            productosFiltrados: [],
            buscarInput: '',
            idProducto: '',
            nombre: '',
            preciosPan: [],
            inventarioPan: [],
            showModalImg: false,
            showModalNombre: false,
            showModalPrecios: false,
            showModalInventario: false,
            showModalAgregar: false,
            showModalCambiarPrecio: false
        }
    }

    static contextType = MyContext;

    componentDidMount = async () => {
        document.title = 'Panes';
        this.context.cambiarRuta('Panes');
        window.addEventListener('resize', this.getHeightWindow);
        window.addEventListener('beforeunload', this.alertUser);
        this.getHeightWindow();
        this.selectSucursales();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectPanes' })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/productos.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                productos: data.productos,
                productosFiltrados: data.productos
            })
        }else{
            toast.error('Error al obtener panes.');
        }
    }
    
    alertUser = (e) => {
        if (this.state.showModalImg || this.state.showModalNombre || this.state.showModalPrecios || this.state.showModalInventario || this.state.showModalAgregar || this.state.showModalCambiarPrecio) {
            e.preventDefault();
            e.returnValue = '';  
        }
    }

    selectSucursales = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectTodasSucursales' })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/sucursales.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            let preciosPan = [];
            data.sucursales.forEach(element => {
                preciosPan.push({ idSucursal: element.idSucursal, precio: '' });
            });
            let inventarioPan = [];
            data.sucursales.forEach(element => {
                inventarioPan.push({ idSucursal: element.idSucursal, piezas: '' });
            });
            this.setState({
                sucursales: data.sucursales,
                preciosPan: preciosPan,
                inventarioPan: inventarioPan
            })
        }else{
            toast.error('Error al obtener sucursales.');
        }
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("contenedorPrincipal")[0]) {
                document.getElementsByClassName("contenedorPrincipal")[0].style.height = (height - 50) + 'px';
        }
    }

    onChangeValueBuscar = async (e) => {
        await this.setState({
            [e.target.name]: e.target.value
        })
        this.buscar();
    }

    buscar = () => {
        if (this.state.buscarInput !== '') {
            let buscar = this.state.buscarInput.toLocaleUpperCase();
            var productosFiltrados = this.state.productos.filter(producto => producto.nombre.toLocaleUpperCase().includes(buscar));
            this.setState({
                productosFiltrados: productosFiltrados
            })
        }else{
            this.setState({
                productosFiltrados: this.state.productos
            })
        }
    }

    showModalImg = (datos) => {
        this.setState({
            showModalImg: true,
            nombre: datos.nombre,
            idProducto: datos.idProducto
        })
    }

    hideModalImg = () => {
        this.setState({
            showModalImg: false,
            nombre: '',
            idProducto: ''
        })
    }

    showModalNombre = (datos) => {
        this.setState({
            showModalNombre: true,
            nombre: datos.nombre,
            idProducto: datos.idProducto
        })
    }

    hideModalNombre = () => {
        this.setState({
            showModalNombre: false,
            nombre: '',
            idProducto: ''
        })
    }

    showModalPrecios = (datos) => {
        let preciosPan = [];
        Object.keys(datos.precios).forEach(key => {
            preciosPan.push({ idSucursal: key, precio: datos.precios[key] });
        });
        this.setState({
            showModalPrecios: true,
            idProducto: datos.idProducto,
            nombre: datos.nombre,
            preciosPan: preciosPan
        })
    }

    hideModalPrecios = () => {
        let preciosPan = [];
        this.state.sucursales.forEach(element => {
            preciosPan.push({ idSucursal: element.idSucursal, precio: '' });
        });
        this.setState({
            showModalPrecios: false,
            idProducto: '',
            nombre: '',
            preciosPan: preciosPan
        })
    }

    showModalInventario = (datos) => {
        let inventarioPan = [];
        Object.keys(datos.inventario).forEach(key => {
            inventarioPan.push({ idSucursal: key, piezas: datos.inventario[key] });
        });
        this.setState({
            showModalInventario: true,
            idProducto: datos.idProducto,
            nombre: datos.nombre,
            inventarioPan: inventarioPan
        })
    }

    hideModalInventario = () => {
        let inventarioPan = [];
        this.state.sucursales.forEach(element => {
            inventarioPan.push({ idSucursal: element.idSucursal, piezas: '' });
        });
        this.setState({
            showModalInventario: false,
            idProducto: '',
            nombre: '',
            inventarioPan: inventarioPan
        })
    }

    showModalAgregar = () => {
        this.setState({
            showModalAgregar: true
        })
    }

    hideModalAgregar = () => {
        let preciosPan = [];
        this.state.sucursales.forEach(element => {
            preciosPan.push({ idSucursal: element.idSucursal, precio: '' });
        });
        let inventarioPan = [];
        this.state.sucursales.forEach(element => {
            inventarioPan.push({ idSucursal: element.idSucursal, piezas: '' });
        });
        this.setState({
            showModalAgregar: false,
            preciosPan: preciosPan,
            inventarioPan: inventarioPan
        })
    }
    
    showModalCambiarPrecio = () => {
        this.setState({
            showModalCambiarPrecio: true
        })
    }

    hideModalCambiarPrecio = () => {
        let preciosPan = [];
        this.state.sucursales.forEach(element => {
            preciosPan.push({ idSucursal: element.idSucursal, precio: '' });
        });
        let inventarioPan = [];
        this.state.sucursales.forEach(element => {
            inventarioPan.push({ idSucursal: element.idSucursal, piezas: '' });
        });
        this.setState({
            showModalCambiarPrecio: false,
            preciosPan: preciosPan,
            inventarioPan: inventarioPan
        })
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangePrecioSucursal = (index, value) => {
        let preciosPan = this.state.preciosPan;
        preciosPan[index].precio = value;
        this.setState({
            preciosPan: preciosPan
        })
    }

    onChangeInventarioSucursal = (e, index) => {
        let inventarioPan = this.state.inventarioPan;
        inventarioPan[index].piezas = e.target.value;
        this.setState({
            inventarioPan: inventarioPan
        })
    }

    eliminar = async (idProducto) => {
        if (window.confirm('¿Esta seguro que desea eliminar este producto?')) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'delete', idProducto: idProducto })
            };
            const response = await fetch('https://admin.panaderialazcano.com/php/DB/productos.php', requestOptions);
            const data = await response.json();
            if (data.error === 0) {
                toast.success('Pan eliminado.');
                this.componentDidMount();
            }else{
                toast.error('Error al agregar pan.');
                console.log(data.mensaje);
            }
        }
    }

    render() { 
        return ( 
            <div className='contenedorPrincipal px-4'>
                <div className="row m-0 py-3">
                    <div className="col-12 d-flex justify-content-between align-items-center px-0">
                        <div className='d-flex justify-content-start align-items-center'>
                            <input type="text" name='buscarInput' className='form-control inputBuscarProducto ms-0 text-center' placeholder='Buscar' value={this.state.buscarInput} onChange={this.onChangeValueBuscar}/>
                            <button className='btn btn-success btnAgregar' onClick={() => this.showModalAgregar()}><i className="bi bi-plus" style={{ fontSize: '40px', color: 'white' }}></i></button>
                        </div>
                        <div className='h-100'>
                            <button className='btn btn-dark h-100' style={{ fontSize: '17px' }} onClick={() => this.showModalCambiarPrecio()}>Modificar precio</button>
                        </div>
                    </div>
                </div>
                <div className="row m-0">
                    <table className='table table-bordered table-striped'>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Nombre</th>
                                {
                                    this.state.sucursales.map((e, index) => 
                                        <th key={index} style={{ whiteSpace: 'nowrap', color: (e.tipo === 'Sucursal' ? 'black' : 'darkblue') }}>{e.nombre}</th>
                                    )
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.productosFiltrados.map((eProducto, index) => 
                                    <tr key={index}>
                                        <td className='pt-3' style={{ width: '1%' }} onClick={() => this.eliminar(eProducto.idProducto)}>
                                            <i className="bi bi-x-lg" style={{ fontSize: '20px', color: '#333333' }}/>
                                        </td>
                                        <td className='text-center' style={{ width: '1%' }} onClick={() => this.showModalImg(eProducto)}>
                                            <img src={'https://pos.panaderialazcano.com/img/productos/' + eProducto.idProducto + '.png'} onError={(e) => {e.target.src = '/default.png'}} alt="" width={'50px'}/>
                                        </td>
                                        <td onClick={() => this.showModalNombre(eProducto)}>
                                            <p className='m-0'>{eProducto.nombre}</p>
                                        </td>
                                        {
                                            this.state.sucursales.map((eSucursal, index) =>
                                                <td key={index} style={{ width: '1%', whiteSpace: 'nowrap' }}>
                                                <div className="row" key={index}>
                                                    <div className="col-12 text-end" style={{ color: (eSucursal.tipo === 'Sucursal' ? 'black' : 'darkblue') }}>
                                                        <p className='m-0' onClick={() => this.showModalInventario(eProducto)}>{eProducto.inventario[eSucursal.idSucursal]} Pzas</p>
                                                    </div>
                                                    <div className="col-12 text-end" style={{ fontWeight: '600', color: (eSucursal.tipo === 'Sucursal' ? 'black' : 'darkblue') }}>
                                                        <p className='m-0' onClick={() => this.showModalPrecios(eProducto)}>${eProducto.precios[eSucursal.idSucursal]}</p>                                                            
                                                    </div>
                                                </div>
                                                </td>
                                            )
                                        }
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <AgregarPan show={this.state.showModalAgregar} hideModal={this.hideModalAgregar} sucursales={this.state.sucursales} nombre={this.state.nombre} preciosPan={this.state.preciosPan} inventarioPan={this.state.inventarioPan} onChangeValue={this.onChangeValue} onChangeInventarioSucursal={this.onChangeInventarioSucursal} onChangePrecioSucursal={this.onChangePrecioSucursal} componentDidMount={this.componentDidMount}/>
                <ChangeImg show={this.state.showModalImg} hideModal={this.hideModalImg} idProducto={this.state.idProducto} nombre={this.state.nombre}/>
                <ChangeNombre show={this.state.showModalNombre} hideModal={this.hideModalNombre} idProducto={this.state.idProducto} nombre={this.state.nombre} onChangeValue={this.onChangeValue} componentDidMount={this.componentDidMount}/>
                <ChangePrecios show={this.state.showModalPrecios} hideModal={this.hideModalPrecios} idProducto={this.state.idProducto} nombre={this.state.nombre} preciosPan={this.state.preciosPan} sucursales={this.state.sucursales} onChangePrecioSucursal={this.onChangePrecioSucursal} componentDidMount={this.componentDidMount}/>
                <ChangeInventario show={this.state.showModalInventario} hideModal={this.hideModalInventario} idProducto={this.state.idProducto} nombre={this.state.nombre} inventarioPan={this.state.inventarioPan} sucursales={this.state.sucursales} onChangeInventarioSucursal={this.onChangeInventarioSucursal} componentDidMount={this.componentDidMount}/>
                <CambiarPrecio show={this.state.showModalCambiarPrecio} hideModal={this.hideModalCambiarPrecio} sucursales={this.state.sucursales} componentDidMount={this.componentDidMount}/>
                <Toaster/>
            </div>
         );
    }
}
 
export default Panes;