import React from 'react';
import MyContextProvider from './contexts/MyContext';
import { BrowserRouter as Router} from 'react-router-dom';
import Routes from './components/Routes';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div>
    <MyContextProvider> 
        <Router>
          <Routes/>
        </Router>
        <Toaster        
          toastOptions={{
            style: {
              background: '#333333',
              color: '#FFFFFF',
              textAlign: 'center',
              fontSize: '14px'
            }
          }}
        />
    </MyContextProvider>
    </div>
  );
}

export default App;