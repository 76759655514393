import React, { Component } from 'react';
import {MyContext} from '../../contexts/MyContext';
import { toast } from 'react-hot-toast';
import '../../css/registroexpendio.css';
import Venta from './Ventas/Venta';
import Merma from './Mermas/Merma';
import VentaExtra from './VentasExtra/VentaExtra';
import Ventas from './Ventas/Ventas';
import Mermas from './Mermas/Mermas';
import VentasExtra from './VentasExtra/VentasExtra';

class RegistroExpendio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sucursales: [],
            idSucursal: '',
            tipo: 'registrar',
            vista: '',
            fecha: this.formatDate(new Date().toLocaleDateString()),
        }
    }

    static contextType = MyContext;

    componentDidMount = async () => {
        document.title = 'Registro de Expendio';
        this.context.cambiarRuta('Registro de Expendio');
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectExpendios' })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/sucursales.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                sucursales: data.sucursales
            })
        }else{
            toast.error('Error al cargar expendios.');
            console.log(data.mensaje);
        }
    }

    formatDate = (fecha) => {
        var fechaFormateada = fecha.split('/');
        fechaFormateada[0] = Number(fechaFormateada[0]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        fechaFormateada[1] = Number(fechaFormateada[1]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        return fechaFormateada.reverse().join('-')
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() { 
        return ( 
            <div className="contenedorPrincipal p-0 registroExpendio">
                <div className="row m-0 px-3" style={{ backgroundColor: '#DFE0DF', boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px' }}>
                    <div className="col-2 my-3">
                        <select name="idSucursal" id="idSucursal" className='selectSucursal form-control h-100 py-1 text-center' onChange={this.onChangeValue}>
                            <option value="">Seleccionar Expendio</option>
                            {
                                this.state.sucursales.map( (e, index) => 
                                    <option key={index} value={e.idSucursal}>{e.nombre}</option>
                                    )
                            }
                        </select>
                    </div>
                    <div className="col-2 my-3">
                        <select name="tipo" id="tipo" className='selectSucursal form-control h-100 py-1 text-center' value={this.state.tipo} onChange={this.onChangeValue}>
                            <option value="registrar">Registrar</option>
                            <option value="registros">Registros</option>
                        </select>
                    </div>
                    <div className="col-2 my-3">
                        <button className={'btn btnVista py-0 ' + (this.state.vista === 'venta' ? 'btn-success text-white' : 'btn-outline-gray')} onClick={ () => this.setState({ vista: 'venta' }) }>Venta</button>
                    </div>
                    <div className="col-2 my-3">
                        <button className={'btn btnVista py-0 ' + (this.state.vista === 'merma' ? 'btn-success text-white' : 'btn-outline-gray')} onClick={ () => this.setState({ vista: 'merma' }) }>Merma</button>
                    </div>
                    <div className="col-2 my-3">
                        <button className={'btn btnVista py-0 ' + (this.state.vista === 'ventaExtra' ? 'btn-success text-white' : 'btn-outline-gray')} onClick={ () => this.setState({ vista: 'ventaExtra' }) }>Venta Extra</button>
                    </div>
                    <div className="col-2 my-3">
                        <input type="date" name="fecha" id="fecha" className="form-control inputFecha btn-outline-gray" value={this.state.fecha} onChange={this.onChangeValue}/>
                    </div>
                </div>
                <div className="row m-0">
                    <div className="col-12">
                        { (this.state.tipo === 'registrar' && this.state.vista === 'venta' && this.state.idSucursal !== '') && <Venta idSucursal={this.state.idSucursal} fecha={this.state.fecha}/> }
                        { (this.state.tipo === 'registrar' && this.state.vista === 'merma' && this.state.idSucursal !== '') && <Merma idSucursal={this.state.idSucursal} fecha={this.state.fecha}/> }
                        { (this.state.tipo === 'registrar' && this.state.vista === 'ventaExtra' && this.state.idSucursal !== '') && <VentaExtra idSucursal={this.state.idSucursal} fecha={this.state.fecha}/> }
                        { (this.state.tipo === 'registros' && this.state.vista === 'venta' && this.state.idSucursal !== '') && <Ventas idSucursal={this.state.idSucursal} fecha={this.state.fecha}/> }
                        { (this.state.tipo === 'registros' && this.state.vista === 'merma' && this.state.idSucursal !== '') && <Mermas idSucursal={this.state.idSucursal} fecha={this.state.fecha}/> }
                        { (this.state.tipo === 'registros' && this.state.vista === 'ventaExtra' && this.state.idSucursal !== '') && <VentasExtra idSucursal={this.state.idSucursal} fecha={this.state.fecha}/> }
                    </div>
                </div>
            </div>
         );
    }
}
 
export default RegistroExpendio;