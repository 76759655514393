import React, { Component } from 'react';
import {MyContext} from '../../contexts/MyContext';
import toast from 'react-hot-toast';
import { Modal } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';

class CobrosExpendios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sucursales: [],
            cobrosExpendios: [],
            cuentasCobrar: [],
            mes: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
            showModalAgregar: false,
            ce_idSucursal: '',
            fecha: this.formatDate(new Date().toLocaleDateString()),
            monto: '',
            descripcion: ''
        }
    }

    static contextType = MyContext;

    componentDidMount = async () => {
        document.title = 'Cobros a Expendios';
        this.context.cambiarRuta('Cuentas por Cobrar');
        window.addEventListener('beforeunload', this.alertUser);
        this.selectCuentasCobrar();
        this.selectCobros();
        this.selectSucursales();
    }
    
    alertUser = (e) => {
        if (this.state.showModalAgregar) {
            e.preventDefault();
            e.returnValue = '';  
        }
    }

    formatDate = (fecha) => {
        var fechaFormateada = fecha.split('/');
        fechaFormateada[0] = Number(fechaFormateada[0]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        fechaFormateada[1] = Number(fechaFormateada[1]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        return fechaFormateada.reverse().join('-')
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    selectCuentasCobrar = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select' })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/cuentascobrar.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                cuentasCobrar: data.cuentasCobrar
            })
        }else{
            toast.error('Error al cargar cuentas por cobrar.');
            console.log(data.mensaje);
        }
    }

    selectCobros = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select' })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/cobrosexpendios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                cobrosExpendios: data.cobrosexpendios
            })
        }else{
            toast.error('Error al obtener cobros.');
            console.log(data.mensaje);
        }
    }

    selectSucursales = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select' })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/expendios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                sucursales: data.sucursales
            })
        }else{
            toast.error('Error al obtener sucursales.');
            console.log(data.mensaje);
        }
    }

    showModalAgregar = () => {
        this.setState({
            showModalAgregar: true
        })
    }

    hideModalAgregar = () => {
        this.setState({
            showModalAgregar: false,
            ce_idSucursal: '',
            fecha: this.formatDate(new Date().toLocaleDateString()),
            monto: '',
            descripcion: ''
        })
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    
    agregarCobro = async (e) => {
        e.preventDefault()
        if (Number(this.state.monto) !== 0) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    operacion: 'insert',
                    usuario: this.context.rootState.theUser.idUsuario,
                    ce_idSucursal: this.state.ce_idSucursal,
                    fecha: this.state.fecha,
                    monto: this.state.monto,
                    descripcion: this.state.descripcion
                })
            };
            const response = await fetch('https://admin.panaderialazcano.com/php/DB/cobrosexpendios.php', requestOptions);
            const data = await response.json();
            if (data.error === 0) {
                toast.success('Cobro registrado.');
                this.hideModalAgregar();
                this.componentDidMount();
            }else{
                toast.error('Error al registrar cobro.');
                console.log(data.mensaje);
            }
        }else{
            toast.error('No puede registrar un cobro de $0.');
        }
    }

    eliminar = async (idCobroExpendio) => {
        if (window.confirm('¿Eliminar cobro?')) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    operacion: 'delete', 
                    usuario: this.context.rootState.theUser.idUsuario,
                    idCobroExpendio: idCobroExpendio
                })
            };
            const response = await fetch('https://admin.panaderialazcano.com/php/DB/cobrosexpendios.php', requestOptions);
            const data = await response.json();
            if (data.error === 0) {
                toast.success('Cobro borrado.');
                this.componentDidMount();
            }else{
                toast.error('Error al eliminar cobro.');
                console.log(data.mensaje);
            }
        }
    }

    render() { 
        return ( 
            <>
            <div className="contenedorPrincipal p-0 registroExpendio">
                <div className="row d-flex justify-content-center m-0 p-3" style={{ backgroundColor: '#DFE0DF', boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px' }}>
                    {
                        this.state.cuentasCobrar.map((e,index) =>
                            <div className="col-2 px-3" key={index}>
                                <div style={{ border: '2px solid black', borderRadius: '5px' }}>
                                    <div className="row m-2">
                                        <div className="col-12" style={{ borderBottom: '2px solid gray' }}>
                                            {e.nombre}
                                        </div>
                                        <div className="col-12 text-center">
                                            $ {this.numberWithCommas(e.monto)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="row mx-0 mt-3 d-flex justify-content-center">
                    <div className="col-2">
                        <input type="month" name="mes" id="mes" className='form-control' value={this.state.mes} onChange={this.onChangeValue}/>
                    </div>
                    <div className="col-2">
                        <button className='btn btn-primary w-100' onClick={this.showModalAgregar}>Agregar</button>
                    </div>
                </div>
                <div className="row mx-0 mt-4">
                    <table className='table table-striped' style={{ marginInline: 'auto', maxWidth: '90%', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', border: '2px solid #eaeaea', borderRadius: '5px' }}>
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Sucursal</th>
                                <th>Descripción</th>
                                <th className='text-center'>Monto</th>
                                <th className='text-center'>-</th>
                            </tr>
                        </thead>
                        <tbody className="table-group-divider">
                            {this.state.cobrosExpendios.map((e, index) =>
                                <tr key={index}>
                                    <td className='px-3' style={{ width: '1%', whiteSpace: 'nowrap' }}>{e.fecha}</td>
                                    <td className='px-3' style={{ width: '1%', whiteSpace: 'nowrap' }}>{e.sucursal}</td>
                                    <td>{e.descripcion}</td>
                                    <td className='text-end px-3' style={{ width: '1%', whiteSpace: 'nowrap' }}>$ {this.numberWithCommas(e.monto)}</td>
                                    <td className='text-center px-3' style={{ width: '1%', whiteSpace: 'nowrap' }}>
                                        <button className='btn btn-sm btn-danger' onClick={() => this.eliminar(e.idCobroExpendio)}><i className="bi bi-trash2"></i></button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal show={this.state.showModalAgregar} onHide={this.hideModalAgregar} centered>
                <form onSubmit={this.agregarCobro}>
                <Modal.Header>
                    Agregar Cobro
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-7">
                            <label htmlFor="ce_idSucursal">Expendio</label>
                            <select name="ce_idSucursal" id="ce_idSucursal" className='form-control' value={this.state.ce_idSucursal} onChange={this.onChangeValue} required>
                                <option value="">Seleccionar</option>
                                {
                                    this.state.sucursales.map((e, index) => 
                                        <option key={index} value={e.idSucursal}>{e.nombre}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className="col-5"></div>
                        <div className="col-6 mt-2">
                            <label htmlFor="fecha">Fecha</label>
                            <input type="date" name="fecha" id="fecha" className='form-control' value={this.state.fecha} onChange={this.onChangeValue} required/>
                        </div>
                        <div className="col-6 mt-2">
                            <label htmlFor="monto">Monto</label>
                            <div className="input-group">
                                <span className='input-group-text'>$</span>
                                <NumericFormat value={this.state.monto} className="form-control text-end" defaultValue='' placeholder='0.0' onValueChange={(values) => {this.setState({ monto: Number(values.value)})}} required allowNegative={false} decimalScale={2} thousandSeparator=','/>
                            </div>
                        </div>
                        <div className="col-12 mt-2">
                            <label htmlFor="descripcion">Descripción</label>
                            <textarea name="descripcion" id="descripcion" cols="30" rows="5" maxLength={500} className='form-control' value={this.state.descripcion} onChange={this.onChangeValue}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-gray' type='button' onClick={this.hideModalAgregar}>Cancelar</button>
                    <button className='btn btn-success' type='submit'>Agregar</button>
                </Modal.Footer>
                </form>
            </Modal>
            </>
         );
    }
}
 
export default CobrosExpendios;