import React, { Component } from 'react';
import { MoonLoader } from 'react-spinners';
import colores from '../css/bootstrap.scss';

class Cargando extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() { 
        return ( 
            <div className='bg-light d-flex justify-content-center align-items-center' style={{ height: window.innerHeight }}>
                <MoonLoader color={colores.dark} loading={true} size={100} />
            </div>
         );
    }
}
 
export default Cargando;