import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';

class CambiarContrasena extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidePassword: true
        }
    }

    submit = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'cambiarContrasena', idUsuario: this.props.idUsuario, contrasena: this.props.contrasena })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/usuarios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            toast.success('Constraseña cambiada.');
            this.props.hideModal();
            this.props.componentDidMount();
        }else{
            toast.error('Error al cambiar contraseña.');
            console.log(data.mensaje);
        }
    }

    togglePassword = () => {
        this.setState(prevState => ({
            hidePassword: !prevState.hidePassword
        }))
    }

    render() { 
        return ( 
            <Modal show={this.props.show} onHide={this.props.hideModal} centered>
                <form onSubmit={this.submit}>
                <Modal.Header className='d-flex justify-content-center'>
                    <h1 className='m-0 text-center' style={{ fontSize: '24px' }}>Cambiar Contraseña Usuario: {this.props.nombre} {this.props.apellidos}</h1>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <label htmlFor="contrasena">Contraseña Nueva</label>
                            <div className="input-group">
                                <input type={this.state.hidePassword ? "password" : "text"} name="contrasena" id="contrasena" className='form-control' pattern="^[A-Za-z\d]{8,20}$" title="La contraseña debe tener entre 8 y 20 caracteres, sin incluir caracteres especiales." value={this.props.contrasena} onChange={this.props.onChangeValue} required/>
                                <button className="input-group-text" type='button' onClick={() => this.togglePassword()}><i className={this.state.hidePassword ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"}></i></button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ backgroundColor: '#333333', color: 'white' }} type='button' onClick={() => this.props.hideModal}>Cancelar</button>
                    <button className='btn btn-success text-white' type='submit'>Cambiar</button>
                </Modal.Footer>
                </form>
            </Modal>
         );
    }
}
 
export default CambiarContrasena;