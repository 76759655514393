import React from 'react';
import { BrowserRouter as Switch } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { LoginRoute } from './LoginRoute';
import Login from './Login';
import Inicio from './Inicio';
import Panes from './Productos/Panes/Panes';
import Abarrotes from './Productos/Abarrotes/Abarrotes';
import Otros from './Productos/Otros/Otros';
import Sucursales from './Sucursales/Sucursales';
import Expendios from './Expendios/Expendios';
import Usuarios from './Usuarios/Usuarios';
import RegistroExpendio from './Expendios/RegistroExpendio';
import CobrosExpendios from './Expendios/CobrosExpendios';

function Routes () {

    return (
        <Switch>
          <LoginRoute path="/login" component={Login}/>
          <PrivateRoute exact path="/" component={Inicio}/>
          <PrivateRoute exact path="/sucursales" component={Sucursales}/>
          <PrivateRoute exact path="/expendios" component={Expendios}/>
          <PrivateRoute exact path="/registroexpendio" component={RegistroExpendio}/>
          <PrivateRoute exact path="/cobrosexpendios" component={CobrosExpendios}/>
          <PrivateRoute exact path="/panes" component={Panes}/>
          <PrivateRoute exact path="/abarrotes" component={Abarrotes}/>
          <PrivateRoute exact path="/otros" component={Otros}/>
          <PrivateRoute exact path="/usuarios" component={Usuarios}/>
        </Switch>
      )
}

export default Routes;