import React, { Component } from 'react';
import {MyContext} from '../../contexts/MyContext';
import '../../css/sucursales.css';
import { Card, CardContent, CardActionArea } from '@mui/material';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';

class Expendios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sucursales: [],
            todasSucursales: [],
            showModalAgregar: false,
            showModalEditar: false,
            idSucursal: '',
            nombre: '',
            idSucursalPrecios: ''
        }
    }

    static contextType = MyContext;

    componentDidMount = async() => {
        document.title = 'Expendios';
        this.context.cambiarRuta('Expendios');
        window.addEventListener('beforeunload', this.alertUser);
        this.selectTodasSucursales();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select' })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/expendios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                sucursales: data.sucursales
            })
        }else{
            toast.error('Error al cargar expendios.');
            console.log(data.mensaje);
        }
    }
    
    alertUser = (e) => {
        if (this.state.nombre !== '') {
            e.preventDefault();
            e.returnValue = '';  
        }
    }

    selectTodasSucursales = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectTodasSucursales' })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/expendios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                todasSucursales: data.sucursales
            })
        }else{
            toast.error('Error al cargar todas las sucursales.');
            console.log(data.mensaje);
        }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    openModalEditar = (idSucursal, nombre) => {
        this.setState({
            showModalEditar: true,
            idSucursal: idSucursal,
            nombre: nombre
        })
    }

    closeModalEditar = () => {
        this.setState({
            showModalEditar: false,
            idSucursal: '',
            nombre: ''
        })
    }

    openModalAgregar = () => {
        this.setState({
            showModalAgregar: true
        })
    }

    closeModalAgregar = () => {
        this.setState({
            showModalAgregar: false,
            nombre: '',
            idSucursalPrecios: '',
        })
    }

    editar = async(e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'update', idSucursal: this.state.idSucursal, nombre: this.state.nombre })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/expendios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            toast.success(data.mensaje);
            this.closeModalEditar();
            this.componentDidMount();
        }else{
            toast.error('Error al editar expendio.');
            console.log(data.mensaje);
        }
    }

    agregar = async(e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'insert', nombre: this.state.nombre, idSucursal: this.state.idSucursalPrecios })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/expendios.php', requestOptions);
        const data = await response.json();
        console.log(data);
        if (data.error === 0) {
            toast.success(data.mensaje);
            this.closeModalAgregar();
            this.componentDidMount();
        }else{
            toast.error('Error al agregar expendio.');
            console.log(data.mensaje);
        }
    }

    eliminar = async() => {
        if (window.confirm('¿Está seguro que desea borrar este expendio y sus datos?')) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'delete', idSucursal: this.state.idSucursal })
            };
            const response = await fetch('https://admin.panaderialazcano.com/php/DB/expendios.php', requestOptions);
            const data = await response.json();
            console.log(data);
            if (data.error === 0) {
                toast.success(data.mensaje);
                this.closeModalEditar();
                this.componentDidMount();
            }else{
                if (data.error === 3) {
                    toast.error(data.mensaje);
                }else{
                    toast.error('Error al borrar expnedio.');
                    console.log(data.mensaje);
                }
            } 
        }
    }

    render() { 
        return ( 
            <>
                <div className='contenedorPrincipal'>
                    <div className="row m-0 p-3">
                        <div className="col-12 d-flex justify-content-center align-items-center">
                            <button className='btn btn-success btnAgregar' onClick={() => this.openModalAgregar()}><i className="bi bi-plus" style={{ fontSize: '40px', color: 'white' }}></i></button>
                        </div>
                    </div>
                    <div className="row m-0 mt-2">
                        {
                            this.state.sucursales.map(e => 
                                <div className="col-4" key={e.idSucursal}>
                                    <Card className='cardSucursal'>
                                        <CardActionArea onClick={() => this.openModalEditar(e.idSucursal, e.nombre)}>
                                            <CardContent>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <i className="bi bi-shop-window"></i>
                                                    </div>
                                                    <div className="col-12">
                                                        <p>{e.nombre}</p>
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </div>    
                            )
                        }
                    </div>
                    <Modal show={this.state.showModalAgregar} onHide={this.closeModalAgregar} centered>
                        <form onSubmit={this.agregar}>
                        <Modal.Header>
                            <h1 className='my-0 mx-auto' style={{ fontSize: '26px' }}>Agregar Expendio</h1>
                        </Modal.Header>
                        <Modal.Body>
                            <label htmlFor="nombre">Nombre</label>
                                <input type="text" name='nombre' id='nombre' className='form-control' value={this.state.nombre} onChange={this.onChangeValue} required/>
                            <label htmlFor="idSucursalPrecios">Importar precios de</label>
                                <select name="idSucursalPrecios" id="idSucursalPrecios" className='form-control' value={this.state.idSucursalPrecios} onChange={this.onChangeValue}>
                                    <option value="">Precios vacíos</option>
                                    {
                                        this.state.todasSucursales.map(e => 
                                            <option key={e.idSucursal} value={e.idSucursal}>{e.tipo} {e.nombre}</option>    
                                        )
                                    }
                                </select>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn' style={{ backgroundColor: '#333333', color: 'white' }} type='button' onClick={() => this.closeModalAgregar()}>Cancelar</button>
                            <button className='btn' style={{ backgroundColor: '#82A036', color: 'white' }} type='submit'>Agregar</button>
                        </Modal.Footer>
                        </form>
                    </Modal>
                    <Modal show={this.state.showModalEditar} onHide={this.closeModalEditar} centered>
                        <form onSubmit={this.editar}>  
                        <Modal.Header>
                            <h1 className='my-0 mx-auto' style={{ fontSize: '26px' }}>Editar Expendio</h1>
                        </Modal.Header>
                        <Modal.Body>
                            <label htmlFor="nombre">Nombre</label>
                                <input type="text" name='nombre' id='nombre' className='form-control' value={this.state.nombre} onChange={this.onChangeValue} required/>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="row w-100">
                                <div className="col-6 text-start">
                                    <button className='btn' style={{ backgroundColor: '#FF6F6F', color: 'white' }} type='button' onClick={() => this.eliminar()}>Eliminar</button>
                                </div>
                                <div className="col-6 text-end">
                                    <button className='btn' style={{ backgroundColor: '#333333', color: 'white' }} type='button' onClick={() => this.closeModalEditar()}>Cancelar</button>
                                    <button className='btn ms-2' style={{ backgroundColor: '#82A036', color: 'white' }} type='submit'>Editar</button>
                                </div>
                            </div>
                        </Modal.Footer>
                        </form>
                    </Modal>
                </div>
            </>
         );
    }
}
 
export default Expendios;