import React, { Component } from 'react';

class CorteDiario extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fecha: this.formatDate(new Date().toLocaleDateString()),
            data: [],
            ventaSeleccionada: []
        }
    }

    componentDidMount = async () => {
        this.select();
    }

    componentDidUpdate = (prevProps) => {
        if ((prevProps.idSucursal !== this.props.idSucursal)) {
            this.select()
            this.setState({
                ventaSeleccionada: []
            })
        }
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    select = async () => {  
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'corteDiario', idSucursal: this.props.idSucursal, fecha: this.state.fecha })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/reportes.php', requestOptions);
        const data = await response.json();
        console.log(data);
        this.setState({
            data: data
        })
    }

    selectVenta = async (e) => {
        let idVenta = e.target.id;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectWhere', idVenta: idVenta })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/ventas.php', requestOptions);
        const data = await response.json();
        console.log(data[0]);
        this.setState({
            ventaSeleccionada: data[0]
        })
    }

    formatDate = (fecha) => {
        var fechaFormateada = fecha.split('/');
        fechaFormateada[0] = Number(fechaFormateada[0]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        fechaFormateada[1] = Number(fechaFormateada[1]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        return fechaFormateada.reverse().join('-')
    }

    onChangeFecha = async (e) => {
        await this.setState({
            [e.target.name]: e.target.value
        })
        this.select();
    }

    onChangeValue = (e) => {

    }

    render() { 
        return ( 
            <>
                <div className="col-2">
                    <label className='ms-1' htmlFor="fecha">Fecha</label>
                    <input type="date" name="fecha" id="fecha" className='form-control' value={this.state.fecha} onChange={this.onChangeFecha}/>
                </div>
                <div className="col-12 mt-3"/>
                <div className="row d-flex justify-content-center">
                    <div className="col-3">
                        {this.state.data.ventas &&
                            this.state.data.ventas.matutino &&
                            <table className='table table-bordered' style={{ width: '100%' }}>
                                <thead className='bg-black text-white'>
                                    <tr className='text-center'>
                                        <th colSpan={2}>Matutino</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Ventas</td>
                                        <td className='text-end'>${this.numberWithCommas(this.state.data.ventas.matutino.total)}</td>
                                    </tr>
                                    <tr>
                                        <td>Pedidos</td>
                                        <td className='text-end'>${this.numberWithCommas(this.state.data.pedidos.matutino.total)}</td>
                                    </tr>
                                    <tr>
                                        <td>Ventas Extra</td>
                                        <td className='text-end'>${this.numberWithCommas(this.state.data.ventasExtra.matutino.total)}</td>
                                    </tr>
                                    <tr style={{ fontWeight: 'bold' }}>
                                        <td>Total</td>
                                        <td className='text-end'>${this.numberWithCommas(Number(this.state.data.ventas.matutino.total) + Number(this.state.data.pedidos.matutino.total) + Number(this.state.data.ventasExtra.matutino.total))}</td>
                                    </tr>
                                </tbody>
                            </table>                        
                        }
                    </div>
                    <div className="col-3">
                        {this.state.data.ventas &&
                            this.state.data.ventas.vespertino &&
                            <table className='table table-bordered' style={{ width: '100%' }}>
                                <thead className='bg-black text-white'>
                                    <tr className='text-center'>
                                        <th colSpan={2}>Vespertino</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Ventas</td>
                                        <td className='text-end'>${this.numberWithCommas(this.state.data.ventas.vespertino.total)}</td>
                                    </tr>
                                    <tr>
                                        <td>Pedidos</td>
                                        <td className='text-end'>${this.numberWithCommas(this.state.data.pedidos.vespertino.total)}</td>
                                    </tr>
                                    <tr>
                                        <td>Ventas Extra</td>
                                        <td className='text-end'>${this.numberWithCommas(this.state.data.ventasExtra.vespertino.total)}</td>
                                    </tr>
                                    <tr style={{ fontWeight: 'bold' }}>
                                        <td>Total</td>
                                        <td className='text-end'>${this.numberWithCommas(Number(this.state.data.ventas.vespertino.total) + Number(this.state.data.pedidos.vespertino.total) + Number(this.state.data.ventasExtra.vespertino.total))}</td>
                                    </tr>
                                </tbody>
                            </table>                        
                        }
                    </div>
                    <div className="col-3">
                        {this.state.data.ventas &&
                            this.state.data.ventas.total &&
                            <table className='table table-bordered' style={{ width: '100%' }}>
                                <thead className='bg-black text-white'>
                                    <tr className='text-center'>
                                        <th colSpan={2}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Ventas</td>
                                        <td className='text-end'>${this.numberWithCommas(this.state.data.ventas.total.total)}</td>
                                    </tr>
                                    <tr>
                                        <td>Pedidos</td>
                                        <td className='text-end'>${this.numberWithCommas(this.state.data.pedidos.total.total)}</td>
                                    </tr>
                                    <tr>
                                        <td>Ventas Extra</td>
                                        <td className='text-end'>${this.numberWithCommas(this.state.data.ventasExtra.total.total)}</td>
                                    </tr>
                                    <tr style={{ fontWeight: 'bold' }}>
                                        <td>Total</td>
                                        <td className='text-end'>${this.numberWithCommas(Number(this.state.data.ventas.total.total) + Number(this.state.data.pedidos.total.total) + Number(this.state.data.ventasExtra.total.total))}</td>
                                    </tr>
                                </tbody>
                            </table>                        
                        }
                    </div>
                    <div className="col-3">
                        {this.state.data.ventas &&
                            this.state.data.ventas.total &&
                            <table className='table table-bordered' style={{ width: '100%' }}>
                                <thead className='bg-black text-white'>
                                    <tr className='text-center'>
                                        <th colSpan={2}>A Entregar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Efectivo</td>
                                        <td className='text-end'>${this.numberWithCommas(Number(this.state.data.ventas.total.efectivo) + Number(this.state.data.pedidos.total.efectivo) + Number(this.state.data.ventasExtra.total.efectivo))}</td>
                                    </tr>
                                    <tr>
                                        <td>Transferencia</td>
                                        <td className='text-end'>${this.numberWithCommas(Number(this.state.data.ventas.total.transferencia) + Number(this.state.data.pedidos.total.transferencia) + Number(this.state.data.ventasExtra.total.transferencia))}</td>
                                    </tr>
                                    <tr style={{ fontWeight: 'bold' }}>
                                        <td>Total</td>
                                        <td className='text-end'>${this.numberWithCommas(Number(this.state.data.ventas.total.efectivo) + Number(this.state.data.pedidos.total.efectivo) + Number(this.state.data.ventasExtra.total.efectivo) + Number(this.state.data.ventas.total.transferencia) + Number(this.state.data.pedidos.total.transferencia) + Number(this.state.data.ventasExtra.total.transferencia))}</td>
                                    </tr>
                                </tbody>
                            </table>                        
                        }
                    </div>
                    <div className="col-12"/>
                    <div className="col-4 p-1">
                        <div style={{ width: '100%', border: '3px solid gray', borderRadius: '5px' }}>
                            {
                                this.state.data.ventas &&
                                <>
                                    <div className="row m-0" style={{ borderBottom: '2px solid gray' }}>
                                        <div className="col-4 text-center">
                                            Hora
                                        </div>
                                        <div className="col-4 text-center">
                                            Estatus
                                        </div>
                                        <div className="col-4 text-center">
                                            Total
                                        </div>
                                        
                                    </div>
                                    {
                                        this.state.data.ventas.ventas &&
                                        this.state.data.ventas.ventas.length > 0 ?
                                            this.state.data.ventas.ventas.map((e) =>                                            
                                                <div className="row p-2 m-0" key={e.idVenta} id={e.idVenta} style={{ borderBottom: '1px solid gray' }} onClick={this.selectVenta}>
                                                    <div className="col-4" id={e.idVenta}>
                                                        {new Date(e.fecha).toLocaleTimeString()}
                                                    </div>
                                                    <div className="col-4 text-center" style={{ textTransform: 'capitalize' }} id={e.idVenta}>
                                                        {e.estatus}
                                                    </div>
                                                    <div className="col-4 text-end" id={e.idVenta}>
                                                        ${this.numberWithCommas(e.total)}
                                                    </div>
                                                </div>
                                            )
                                        :
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                No hay ventas registradas éste día.
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                    <div className="col-8 p-1">
                            <div style={{ width: '100%', border: '3px solid gray', borderRadius: '5px' }}>
                                <div className="row py-3 m-0" style={{ borderBottom: '2px solid gray' }}>
                                    <div className="col-12 text-center">
                                        <h6 className='m-0'>Detalles de la Venta</h6>
                                    </div>
                                </div>
                                {
                                    this.state.ventaSeleccionada.idVenta &&
                                        <>
                                            <div className="row m-0" style={{ borderBottom: '2px solid gray' }}>
                                                <div className="col-3 text-center py-2" style={{ borderRight: '2px solid gray', borderBottom: '2px solid gray' }}>
                                                    <h6 className='m-0'>Fecha: {new Date(this.state.ventaSeleccionada.fecha).toLocaleDateString()}</h6>
                                                </div>
                                                <div className="col-3 text-center py-2" style={{ borderRight: '2px solid gray', borderBottom: '2px solid gray' }}>
                                                    <h6 className='m-0'>Hora: {new Date(this.state.ventaSeleccionada.fecha).toLocaleTimeString()}</h6>
                                                </div>
                                                <div className="col-3 text-center py-2" style={{ borderRight: '2px solid gray', borderBottom: '2px solid gray' }}>
                                                    <h6 className='m-0'>Pagado: {this.state.ventaSeleccionada.metodoPago.charAt(0).toUpperCase() + this.state.ventaSeleccionada.metodoPago.slice(1)}</h6>
                                                </div>
                                                <div className="col-3 text-center py-2" style={{ borderBottom: '2px solid gray' }}>
                                                    <h6 className='m-0'>Total: ${this.numberWithCommas(this.state.ventaSeleccionada.total)}</h6>
                                                </div>
                                                <div className="col-12 py-2">
                                                    <h6 className='m-0'>Comentario:</h6>
                                                    <p className='m-0'>{this.state.ventaSeleccionada.comentario}</p>
                                                </div>
                                            </div>
                                            {this.state.ventaSeleccionada.productos.map((e, index) => 
                                                <div className="row py-3 m-0" key={index} style={{ borderBottom: '1px solid gray' }}>
                                                    <div className="col-4 text-center">
                                                        {e.nombre}
                                                    </div>
                                                    <div className="col-4 text-center">
                                                        {e.cantidad} Pzs.
                                                    </div>
                                                    <div className="col-4 text-center">
                                                        ${this.numberWithCommas(e.precioUnitario)}
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                }
                            </div>
                    </div>
                </div>
            </>
         );
    }
}
 
export default CorteDiario;