import React from 'react';
import '../css/navbar.css';
import { MyContext } from '../contexts/MyContext';

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    static contextType = MyContext;

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    getTotalEfectivo = () => {
        let total = 0;
        let cuentas = this.state.cuentas;
        cuentas = cuentas.filter(e => e.nombre.toLowerCase().includes('efectivo'));
        cuentas.forEach(element => {
            total = total + Number(element.saldo)
        });
        return total
    }
    
    getTotal = () => {
        let total = 0;
        let cuentas = this.state.cuentas;
        cuentas.forEach(element => {
            total = total + Number(element.saldo)
        });
        return total
    }
    
    render() { 
        return ( 
            <>
                <div className='row m-0 py-0 navbar'>
                    <div className="col-2 h-100 py-2 d-flex align-items-center">
                        <button onClick={this.props.toggleSidebar} className='btnToggle btn p-0 ms-2'><i className="bi bi-list" style={{ fontSize: '26px', color: 'white' }}></i></button>
                    </div>
                    <div className="col-1 h-100 p-0 d-none align-items-center justify-content-end">
                        <button className='btn btn-outline-light d-flex me-2' id='btnHeaderReload' onClick={() => this.select() }><i className="bi bi-arrow-clockwise"></i></button>
                    </div>
                    <div className="col-6 d-flex align-items-center p-0" style={{ whiteSpace: 'nowrap', overflowX: 'hidden' }}>
                        <p className='m-0 text-white' style={{ fontSize: '14px' }}>{this.context.rootState.ruta}</p>
                        <p className='m-0 text-white ms-1' hidden={this.context.rootState.subruta === ''}><i className="bi bi-arrow-right-short"></i></p>
                        <p className='m-0 text-white' style={{ fontSize: '14px' }}>{this.context.rootState.subruta}</p>
                    </div>
                </div>
            </>
         );
    }
}
 
export default Navbar;