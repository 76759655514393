import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';

class ChangeNombre extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        }
    }

    editar = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'updateNombre', idProducto: this.props.idProducto, nombre: this.props.nombre })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/productos.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            toast.success('Pan editado');
            this.props.hideModal();
            this.props.componentDidMount();
        }else{
            toast.error('Error al editar pan.');
            console.log(data.mensaje);
        }
    }

    render() { 
        return ( 
            <Modal show={this.props.show} onHide={this.props.hideModal} centered>
                <form onSubmit={this.editar}>
                <Modal.Header>
                    <h1 className='m-0' style={{ fontSize: '24px' }}>Cambiar Nombre {this.props.nombre}</h1>
                </Modal.Header>
                <Modal.Body>
                    <label htmlFor="nombre">Nombre</label>
                        <input type="text" name="nombre" id="nombre" className='form-control' value={this.props.nombre} onChange={this.props.onChangeValue} required/>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ backgroundColor: '#333333', color: 'white' }} type='button' onClick={this.props.hideModal}>Cancelar</button>
                    <button className='btn btn-success text-white' type='submit'>Editar</button>
                </Modal.Footer>
                </form>
            </Modal>
         );
    }
}
 
export default ChangeNombre;