import React, { Component } from 'react';
import { MyContext } from '../../../contexts/MyContext';
import toast from 'react-hot-toast';
import { NumericFormat } from 'react-number-format';

class VentaExtra extends Component {

    static contextType = MyContext;

    constructor(props) {
        super(props);
        this.state = {
            concepto: '',
            monto: ''
        }
    }

    componentDidMount = async () => {
        window.addEventListener('resize', this.getHeightWindow);
        window.addEventListener('beforeunload', this.alertUser);
        this.getHeightWindow();
    }
    
    alertUser = (e) => {
        if (this.state.concepto !== '' || Number(this.state.monto) !== 0) {
            e.preventDefault();
            e.returnValue = '';  
        }
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("containerVentas")[0]) {
            document.getElementsByClassName("containerVentas")[0].style.height = (height - 240) + 'px';
            document.getElementsByClassName("containerDetalleVenta")[0].style.height = (height - 170) + 'px';
        }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value 
        });
    }

    agregarVentaExtra = async () => {
        if (this.state.concepto !== '' && Number(this.state.monto) !== 0) {
            if (window.confirm('¿Está seguro de finalizar la venta?')) {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        operacion: 'insert', 
                        usuario: this.context.rootState.theUser.idUsuario,
                        sucursal: this.props.idSucursal,
                        fecha: this.props.fecha,
                        concepto: this.state.concepto,
                        total: this.state.monto
                    })
                };
                const response = await fetch('https://admin.panaderialazcano.com/php/DB/ventasextra.php', requestOptions);
                const data = await response.json();
                console.log(data);
                if (data.error === 0) {
                    toast.success(data.mensaje);
                    this.setState({
                        concepto: '',
                        monto: ''
                    });
                    this.componentDidMount();
                }else{
                    toast.error('Error al registrar venta extra.');
                    console.log(data);
                }
            }
        }else{
            toast.error('Para eso, llene los campos solicitados.');
        }
    }

    render() { 
        return ( 
            <>
                <div className="container-fluid ventas">
                    <div className="row d-flex justify-content-center">
                        <div className="col-5 px-4 pt-4">
                            <div className="row">
                                <div className='containerDetalleVenta mt-4' style={{ backgroundColor: '#e1e4c8', borderRadius: '5px' }}>
                                    <div className="row py-3" style={{ borderBottom: '5px solid #afc37f' }}>
                                        <div className="col-12 text-center">
                                            <h5 className='m-0'>Registrar Venta Extra</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-9 pt-2">
                                                <label htmlFor="concepto" className='ms-1'>Concepto</label>
                                                    <input type="text" id='concepto' className='form-control' name='concepto' value={this.state.concepto} onChange={this.onChangeValue}/>
                                        </div>
                                        <div className="col-3 pt-2">
                                            <label htmlFor="monto" className='ms-1'>Monto</label>
                                                <NumericFormat value={this.state.monto} className="form-control text-end" defaultValue='' placeholder='$0.0' onValueChange={(values) => {this.setState({ monto: Number(values.value)})}} allowNegative={false} decimalScale={2} thousandSeparator=',' prefix={'$'}/>
                                        </div>
                                        <div className="col-12">
                                            <button className='my-3 mx-auto d-flex btn btnFinalizarVenta px-4' onClick={this.agregarVentaExtra}>Agregar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
         );
    }
}
 
export default VentaExtra;