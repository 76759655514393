import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';

class ChangeInventario extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    editar = async (e) => {
        e.preventDefault();
        if (window.confirm('Recuerde modificar inventario ÚNICAMENTE si no se están realizando ventas de este producto, de lo contrario podria generar errores en el inventario.')) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'updateInventario', idProducto: this.props.idProducto, inventario: this.props.inventarioPan })
            };
            const response = await fetch('https://admin.panaderialazcano.com/php/DB/productos.php', requestOptions);
            const data = await response.json();
            if (data.error === 0) {
                toast.success('Pan editado');
                this.props.hideModal();
                this.props.componentDidMount();
            }else{
                toast.error('Error al editar pan.');
                console.log(data.mensaje);
            }
        }
    }

    render() { 
        return ( 
            <Modal show={this.props.show} onHide={this.props.hideModal} centered>
                <form onSubmit={this.editar}>
                <Modal.Header>
                    <h1 className='m-0' style={{ fontSize: '24px' }}>Cambiar Inventario {this.props.nombre}</h1>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.props.sucursales.map(e =>
                                <div key={e.idSucursal} className='input-group mt-2'>
                                    <span className='input-group-text'>{e.tipo} {e.nombre}</span>
                                    <input type="number" name="piezas" id="piezas" className='form-control text-end' min={0} step={1} required value={this.props.inventarioPan[this.props.inventarioPan.findIndex(item => item.idSucursal === e.idSucursal)].piezas} onChange={(_) => this.props.onChangeInventarioSucursal(_,this.props.inventarioPan.findIndex(item => item.idSucursal === e.idSucursal))}/>
                                </div>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ backgroundColor: '#333333', color: 'white' }} type='button' onClick={this.props.hideModal}>Cancelar</button>
                    <button className='btn btn-success text-white' type='submit'>Editar</button>
                </Modal.Footer>
                </form>
            </Modal>
         );
    }
}
 
export default ChangeInventario;