import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cargando from './Cargando';
import {MyContext} from '../contexts/MyContext';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { useState } from 'react';
 
export const PrivateRoute = ({ component: Component, ...rest}) => {
    const contexto = useContext(MyContext);
    const [toggledSidebar, toggleSidebar] = useState(false);

    const toggle = () => {
        toggleSidebar(!toggledSidebar);
    }

    return (
        <>
            {
                <Route
                {...rest}
                render={ () => {
                    return (contexto.rootState.isAuth === true) ? 
                                <div className='app'>
                                    <Sidebar toggledSidebar={toggledSidebar} toggleSidebar={toggle}/>
                                    <main>
                                        <div className="container-fluid p-0 m-0">
                                            <Navbar toggleSidebar={toggle}/>
                                            <Component/>
                                        </div>
                                    </main>
                                </div>
                            :   
                                (contexto.rootState.isAuth !== '') ? 
                                    <Redirect to='/login'/> 
                                : 
                                    <Cargando/>
                }}
                >
                </Route>
            }
        </>
    )
}