import React from 'react'
import {MyContext} from '../contexts/MyContext'

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo:{
                userid:'',
                password:''
            },
            errorMsg:'',
            successMsg:'',
            usuarios: []
        }
        this._isMounted = false;
    }

    static contextType = MyContext;

    componentDidMount = async () => {
        document.title = 'Iniciar Sesión';
        this._isMounted = true;
        this._isMounted && this.select();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    select = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select' })
        };
        const response = await fetch('https://admin.panaderialazcano.com/php/DB/usuarios.php', requestOptions);
        const data = await response.json();
        this._isMounted && this.setState({
            usuarios: data
        })
    }

    // On change input value (email & password)
    onChangeValue = (e) => {
        this.setState({
            ...this.state,
            userInfo:{
                ...this.state.userInfo,
                [e.target.name]:e.target.value
            }
        });
    }

    submitForm = async (event) => {
        event.preventDefault();
        const data = await this.context.loginUser(this.state.userInfo);
        if(data.success && data.token){
            this.setState({
                ...this.state,
            });
            localStorage.setItem('loginToken', data.token);
            await this.context.isLoggedIn();
            window.location.href = '/';
        }
        else{
            this.setState({
                ...this.state,
                successMsg:'',
                errorMsg:data.message
            });
        }
    }

    redirect = () => {
        if (this.context.rootState.isAuth === true) {
            window.location.href = '/'
        }
    }

    render() { 
        return ( 
            <>
                <div className="login" onLoad={this.redirect()}>
                    <div className="row">
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-9 col-xl-3 col-lg-5 col-md-6 col-sm-7 mt-5">
                            <div className="card">
                                <div className="card-header text-center">
                                    <h1>Inicio de Sesión</h1>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.submitForm}>
                                        <label>Usuario</label>
                                            <select
                                                className='form-control' 
                                                name="userid" 
                                                required
                                                value={this.state.userInfo.userid}
                                                onChange={this.onChangeValue}
                                                >
                                                <option value="">Seleccionar</option>
                                                {this.state.usuarios.map(e => 
                                                    <option key={e.idUsuario} value={e.idUsuario}>{e.nombre + ' ' + e.apellidos}</option>
                                                )}
                                            </select>
                                        <label className="mt-3">Contraseña</label>
                                            <input 
                                            className="form-control"
                                            name="password" 
                                            type="password" 
                                            required 
                                            placeholder="Ingrese su contraseña" 
                                            value={this.state.userInfo.password} 
                                            onChange={this.onChangeValue} 
                                        />
                                        <button type="submit" className="form-control mt-3 btn btn-secondary">Ingresar</button>
                                    </form>
                                </div>
                                <div className="card-footer">
                                    {this.state.errorMsg}
                                    {this.state.successMsg}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
         );
    }
}
 
export default Login;